import {Component} from '@angular/core'
import {ConfigService} from '../../services/config.service'
import {ADMIN_ROUTE_PATH, START_ROUTE_PATH} from "../../application/data-types"

@Component({
  selector: 'spb-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent {
  constructor(
    public configService: ConfigService
  ) {
  }

  protected readonly START_ROUTE_PATH = START_ROUTE_PATH
  protected readonly ADMIN_ROUTE_PATH = ADMIN_ROUTE_PATH
}
