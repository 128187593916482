<div class="header">
  <!-- Router link here is default ["/"] -->
  <spb-logo routerLink="/start" title="Borgo Kalpylator"></spb-logo>
  <div [style.flex-grow]="1"></div>
  <div *ngIf="(configService.logInState$ | async) as user" class="menu">
    <span>{{ user.name }}</span>
    <button [matMenuTriggerFor]="menu" mat-button>
      <mat-icon>menu</mat-icon>
    </button>
  </div>
</div>


<mat-menu #menu="matMenu">
  <button [routerLink]="[START_ROUTE_PATH]" mat-menu-item>Kalpylator</button>
  <button *ngIf="configService.isAdmin$()" [routerLink]="[ADMIN_ROUTE_PATH]" mat-menu-item>Admin tjänster</button>
  <button (click)="configService.logout()" mat-menu-item>Logga ut</button>
</mat-menu>
